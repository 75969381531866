import React, { useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import Sidebar from "../ds/Sidebar/Sidebar";
import Navbar from "../ds/Navbar/Navbar";
import "./mainLayout.scss";
import NavbarMobile from "../components/molecules/NavbarMobile/NavbarMobile";
import HeaderAuthed from "../components/organisms/Header/HeaderAuthed";
import AccountPopUp from "../components/molecules/AccountPopUp/AccountPopUp";
import { Returntext } from "../pages/Landings/common/returnText";
import { useContent, useLanguages } from "../hooks/content";
import BtnCircular from "../ds/BtnCircular/BtnCircular";
import BtnStandardIconText from "../ds/BtnStandardIconText/BtnStandardIconText";
import {
  useDeletePaymentCardMutation,
  usePaymentCardsQuery,
} from "../hooks/cards";
import PaymentIframe from "../components/molecules/BogPayIframe/BogPayIframe";
import AddressPopUp from "../components/molecules/AddressPopUp/AddressPopUp";
import { useAddressDelete, useCustomerAddresses } from "../hooks/address";
import {
  useAddOrder,
  useCreateDeclaration,
  useDeleteOrder,
  useFileUpload,
  useOrders,
} from "../hooks/orders";
import { useSnackbar } from "notistack";
import axios from "axios";
import PaymentDrawer from "../components/organisms/PaymentDrawer/PaymentDrawer";
import DeliveryDrawer from "../components/molecules/DeliveryDrawer/DeliveryDrawer";
import ProductDrawerBody from "../ds/ProductDrawerBody/ProductDrawerBody";
import Drawer from "../ds/Drawer/Drawer";
import NotDeclaredDrawerBody from "../ds/DeclareBodies/NotDeclaredDrawerBody";
import DeclaredDrawerBody from "../ds/DeclareBodies/DeclaredDrawerBody";
import DeclareDrawerBody from "../ds/DeclareDrawerBody/DeclareDrawerBody";
import OrderDrawerBody from "../ds/OrderDrawerBody/OrderDrawerBody";
import AddressDrawer from "../components/organisms/AddressDrawer/AddressDrawer";
import VatPInfo from "../components/organisms/VatPInfo/VatPInfo";
import Custom from "../components/organisms/Custom/Custom";
import TrackingWayDrawer from "../components/organisms/TrackingWayDrawer/TrackingWayDrawer";
import PInfoDrawer from "../components/organisms/PInfoDrawer/PInfoDrawer";
import TipsDrawer from "../components/organisms/TipsDrawer/TipsDrawer";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import { useShippingAddresses } from "../hooks/shippingAddresses";

import { ReactComponent as CloseIcon } from "../icons/x.svg";
import { ReactComponent as AddIcon } from "../icons/add.svg";
import PayedDrawer from "../components/organisms/PaymentDrawer/PayedDrawer";
import { useTransactions } from "../hooks/transactions";
import AccoundSecondaryEditPP from "../components/molecules/AccoundSecondaryEditPP/AccoundSecondaryEditPP";
import { useDispatch, useSelector } from "react-redux";
import {
  setCustomDrawer,
  setDeclareDrawerActive,
  setDeliveryDrawer,
  setPaymentDrawer,
  setTrackingDrawerActive,
  setTrackingWay,
} from "../redux/actions";
import RestrictedItemsDrawer from "../components/organisms/RestrictedItemsDrawer/RestrictedItemsDrawer";

const MainLayout = ({
  web,
  mob,
  setPDrawerActive,
  setPopUpActive,
  popUpActive,
  setOrderId,
  setShowModal,
  deleteItem,
  setDeleteItem,
  orderId,
  showModal,
  activeItem,
  setAddAddressPopUp,
  setEditAddressPopUp,
  addAddressPopUp,
  editAddressPopUp,
  activeOrder,
  setActiveOrder,
  vatDrawer,
  setVatDrawer,
  deleteOrder,
  setDeleteOrder,
  payedDrawer,
  setPayedDrawer,
  declareClose,
  setDeclareClose,
  restrictedItemsDrawer,
  setRestrictedItemsDrawer,
  pDrawerActive,
}) => {
  // const { data: userData } = useUserData();
  const { status } = useParams();

  const [tracking, setTracking] = useState("");
  const [title, setTitle] = useState("");
  const [country, setCountry] = useState("usa");
  const [amount, setAmount] = useState("");
  const [files, setFiles] = useState([]);
  const [website, setWebsite] = useState("");
  const [itemCategory, setItemCategory] = useState("");
  const [addressDrawer, setAddressDrawer] = useState(false);
  const [deleteFile, setDeleteFile] = useState(false);
  const [activeFile, setActiveFile] = useState([]);

  const [vatPInfoDrawer, SetVatPInfoDrawer] = useState(false);
  const [pInfoDrawer, setPInfoDrawer] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  //
  const [activeDec, setActiveDec] = useState([]);
  const [tipsDrawerActive, setTipsDrawerActive] = useState(false);
  //

  // errors
  const [trackingError, setTrackingError] = useState(false);
  // errors

  // custom
  const [step, setStep] = useState();
  //
  const [productDrawerActive, setProductDrawerActive] = useState(false);

  const deletePaymentCardMutation = useDeletePaymentCardMutation();
  const { transactions } = useTransactions();

  const { deleteOrderById } = useDeleteOrder(user?.id);

  const handleDeleteOrder = (orderId) => {
    try {
      deleteOrderById(orderId);
      setDeleteOrder(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteCard = (cardId) => {
    try {
      deletePaymentCardMutation.mutate(cardId);
      setDeleteItem(false);
      enqueueSnackbar(
        Returntext(contentManagementData, "ErrorMessages-carddelete1", lang),
        {
          variant: "success",
        }
      );
    } catch (err) {
      enqueueSnackbar(
        Returntext(contentManagementData, "ErrorMessages-carddelete2", lang),
        {
          variant: "error",
        }
      );
    }
  };

  const { mutateDeleteAddress } = useAddressDelete();

  const handleDeleteAddress = (addressId) => {
    try {
      mutateDeleteAddress(addressId);
    } catch (error) {}
  };

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  const { handleFileUpload } = useFileUpload();
  const { customerAddresses } = useCustomerAddresses();
  const { data: paymentCards } = usePaymentCardsQuery();
  const { shippingAddress } = useShippingAddresses();

  const [address, setAddress] = useState(
    customerAddresses !== undefined && customerAddresses.length !== 0
      ? customerAddresses.find((address) => address.isDefault === true)
      : ""
  );
  //
  const [all, setAll] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const DrawerTrigger = (setDrawerActive, drawerActive) => {
    setDrawerActive(!drawerActive);
  };

  const isOrderRoute = () => {
    const val = window.location.pathname.startsWith("/orders");
    return val;
  };

  const isAddressRoute = () => {
    const val = window.location.pathname.startsWith("/delivery");
    return val;
  };

  const isWalletRoute = () => {
    const val = window.location.pathname.startsWith("/wallet");
    return val;
  };

  const { orders, refetchOrders } = useOrders(user?.id, status);

  const { handleAddOrder } = useAddOrder();
  const { handleCreateDeclaration } = useCreateDeclaration();
  // const { handleUploadFile } =
  //   useUploadDeclarationFile();

  const [amountError, setAmountError] = useState(false);
  const [websiteError, setWebsiteError] = useState(false);
  const [itemCategoryError, setItemCategoryError] = useState(false);

  const trackingDrawerActive = useSelector(
    (state) => state.trackingDrawerActive
  );

  const declareDrawerActive = useSelector((state) => state.declareDrawerActive);
  const customDrawer = useSelector((state) => state.customDrawer);
  const paymentDrawer = useSelector((state) => state.paymentDrawer);
  const deliveryDrawer = useSelector((state) => state.deliveryDrawer);
  const trackingWay = useSelector((state) => state.trackingWay);
  const aPopUpActive = useSelector((state) => state.aPopUpActive); // Add this line

  const dispatch = useDispatch();

  const createNewDeclaration = async (activeOrder) => {
    try {
      if (website === "") {
        setWebsiteError(true);
      } else {
        setWebsiteError(false);
      }
      if (amount === "") {
        setAmountError(true);
      } else {
        setAmountError(false);
      }
      if (itemCategory === "") {
        setItemCategoryError(true);
      } else {
        setItemCategoryError(false);
      }

      if (website === "" || amount === "" || itemCategory === "") {
        enqueueSnackbar(
          Returntext(contentManagementData, "ErrorMessages-settings", lang),
          {
            variant: "error",
          }
        );
      } else {
        await handleCreateDeclaration({
          order_id: activeOrder.id,
          website: website,
          category: itemCategory,
          price: amount,
          status: "Declared",
          files: files,
        });
        refetchOrders();
        resetStateVariables();
        setProductDrawerActive(false);
        dispatch(setDeclareDrawerActive(true));
        enqueueSnackbar(
          Returntext(contentManagementData, "ErrorMessages-declaration4", lang),
          {
            variant: "success",
          }
        );
      }
    } catch (err) {
      enqueueSnackbar(err.message, {
        variant: "error",
      });
    }
  };

  const addOrder = () => {
    if (tracking !== "") {
      handleAddOrder({
        tracking_number: tracking,
        customer_id: user?.id,
        name: title,
        custom_status: "0",
        status: "AWAITING",
      }).then((newOrder) => {
        if (newOrder && newOrder.data) {
          setActiveOrder(newOrder.data);
          dispatch(setDeclareDrawerActive(!declareDrawerActive));
          dispatch(setTrackingDrawerActive(!trackingDrawerActive));
          setTrackingError(false);

          enqueueSnackbar(
            Returntext(contentManagementData, "ErrorMessages-Package", lang),
            {
              variant: "success",
            }
          );
        } else {
          setTrackingError(true);
          enqueueSnackbar(
            Returntext(contentManagementData, "ErrorMessages-Tracking", lang),
            {
              variant: "error",
            }
          );
        }
      });
      setTrackingError(false);
    } else {
      enqueueSnackbar(
        Returntext(contentManagementData, "ErrorMessages-settings", lang),
        {
          variant: "error",
        }
      );
      if (tracking === "") {
        setTrackingError(true);
      } else {
        setTrackingError(false);
      }
    }
  };

  // state management clear function
  const resetStateVariables = () => {
    setItemCategory("");
    setAmount("");
    setTracking("");
    setTitle("");
    setFiles([]);
  };

  // cancel and back click action
  const cancelClick = () => {
    dispatch(setDeclareDrawerActive(false));
    dispatch(setTrackingDrawerActive(false));
    dispatch(setCustomDrawer(false));
    dispatch(setTrackingWay(false));
    setPInfoDrawer(false);
    setTipsDrawerActive(false);
    setVatDrawer(false);
    SetVatPInfoDrawer(false);
    resetStateVariables();
    setActiveOrder([]);
  };

  const cancelRest = () => {
    setRestrictedItemsDrawer(false);
  };

  const bacKClick = () => {
    dispatch(setDeclareDrawerActive(true));
    setProductDrawerActive(false);
    resetStateVariables();
  };

  const triggerMoreProductDrawer = () => {
    setProductDrawerActive(true);
    dispatch(setDeclareDrawerActive(false));
    resetStateVariables();
  };

  useEffect(() => {
    if (
      isOrderRoute &&
      activeOrder !== undefined &&
      Object.keys(activeOrder).length > 0 &&
      !pDrawerActive
    ) {
      setTracking(
        activeOrder.tracking_number !== null
          ? activeOrder.tracking_number
          : tracking
      );
      setTitle(activeOrder.name !== null ? activeOrder.name : title);
      setCountry("usa");
    }
  }, [activeOrder, title, tracking, website, isOrderRoute]);

  useEffect(() => {
    if (
      activeOrder?.declaration !== undefined &&
      activeOrder?.declaration?.length > 0
    ) {
      setWebsite(
        activeOrder?.declaration[0]?.website !== null
          ? activeOrder?.declaration[0]?.website
          : website
      );
    }
  }, [activeOrder]);

  useEffect(() => {
    if (isOrderRoute && orders.length > 0 && activeOrder.id) {
      const updatedOrder = orders.find((order) => order.id === activeOrder.id);
      if (updatedOrder) {
        setActiveOrder(updatedOrder);
      }
    }
  }, [activeOrder, orders, isOrderRoute]);
  const access_token = Cookies.get("access_token");

  const updateWebsite = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/declarations/${activeOrder.id}?accessToken=${access_token}`,
        {
          website: website,
        }
      );
      resetStateVariables();
      setActiveOrder([]);
    } catch (err) {
      console.log(err);
    }
  };

  const updateDeclaration = async () => {
    try {
      if (
        activeOrder.declaration !== undefined &&
        activeOrder.declaration.length !== 0
      ) {
        await axios.put(
          `${process.env.REACT_APP_API_URL}/orders/${activeOrder.id}?accessToken=${access_token}`,
          {
            declaration_status: "DECLERED",
          }
        );
        resetStateVariables();
        setActiveOrder([]);
        updateWebsite();
        refetchOrders();
        dispatch(setDeclareDrawerActive(!declareDrawerActive));
        enqueueSnackbar(
          Returntext(contentManagementData, "ErrorMessages-declaration", lang),
          {
            variant: "success",
          }
        );
      } else {
        enqueueSnackbar(
          Returntext(contentManagementData, "ErrorMessages-product1", lang),
          {
            variant: "error",
          }
        );
        refetchOrders();
      }
    } catch (error) {
      console.error(
        Returntext(contentManagementData, "ErrorMessages-declaration2", lang),
        error.message
      );
    }
  };

  const PaymentDrawerComponent = (
    <PaymentDrawer
      cancelClick={() => {
        dispatch(setPaymentDrawer(!paymentDrawer));
        setActiveOrder([]);
        setAll(false);
        resetStateVariables();
        setActiveOrder([]);
      }}
      useraddress={address}
      activeOrder={activeOrder}
      nextClick={() => {
        dispatch(setDeliveryDrawer(!deliveryDrawer));
        dispatch(setPaymentDrawer(!paymentDrawer));
      }}
      setAddressDrawer={setAddressDrawer}
      all={all}
      address={address}
      setAddress={setAddress}
      setActiveOrder={setActiveOrder}
    />
  );

  const PayedDrawerComponent = (
    <PayedDrawer
      cancelClick={() => {
        DrawerTrigger(setPayedDrawer, payedDrawer);
        setActiveOrder([]);
        setAll(false);
        resetStateVariables();
        setActiveOrder([]);
      }}
      useraddress={address}
      activeOrder={activeOrder}
      nextClick={() => {
        dispatch(setDeliveryDrawer(!deliveryDrawer));
        dispatch(setPaymentDrawer(!paymentDrawer));
      }}
      setAddressDrawer={setAddressDrawer}
      all={all}
      address={address}
      setAddress={setAddress}
      setActiveOrder={setActiveOrder}
    />
  );

  const DeliveryDrawerComponent = (
    <DeliveryDrawer
      cancelClick={() => {
        dispatch(setDeliveryDrawer(!deliveryDrawer));
        setActiveOrder([]);
      }}
      activeOrder={activeOrder}
    />
  );

  const CustomComponent = (
    <Custom
      cancelClick={() => {
        dispatch(setCustomDrawer(false));
        setActiveOrder([]);
      }}
      activeOrder={activeOrder}
      setActiveOrder={setActiveOrder}
      setPInfoDrawer={setPInfoDrawer}
      setTipsDrawerActive={setTipsDrawerActive}
      tipsDrawerActive={tipsDrawerActive}
      setStep={setStep}
      step={step}
      files={files}
      setFiles={setFiles}
    />
  );

  const ProductDrawerBodyContent = (
    <ProductDrawerBody
      itemCategory={itemCategory}
      setItemCategory={setItemCategory}
      amount={amount}
      setAmount={setAmount}
      amountError={amountError}
      itemCategoryError={itemCategoryError}
    />
  );

  const ProductDrawerComponent = (
    <Drawer
      headerTitle={Returntext(contentManagementData, "productDrawerT", lang)}
      body={ProductDrawerBodyContent}
      onClick={() => {
        createNewDeclaration(activeOrder);
      }}
      cancel={bacKClick}
      btnMainTxt={Returntext(contentManagementData, "productDrawerB", lang)}
      size={"s"}
    />
  );

  const NotDeclaredBody = (
    <NotDeclaredDrawerBody
      itemCategory={itemCategory}
      setItemCategory={setItemCategory}
      amount={amount}
      setAmount={setAmount}
      amountError={amountError}
      itemCategoryError={itemCategoryError}
    />
  );

  const DeclaredBody = (
    <DeclaredDrawerBody
      activeOrder={activeOrder}
      setDeleteItem={setDeleteItem}
      activeDec={activeDec}
      setActiveDec={setActiveDec}
    />
  );

  const DrawerBody = (
    <DeclareDrawerBody
      website={website}
      setWebsite={setWebsite}
      files={files}
      setFiles={setFiles}
      triggerMoreProductDrawer={triggerMoreProductDrawer}
      activeOrder={activeOrder}
      deleteItem={deleteItem}
      setDeleteItem={setDeleteItem}
      activeDec={activeDec}
      websiteError={websiteError}
      setActiveOrder={setActiveOrder}
      setDeleteFile={setDeleteFile}
      setActiveFile={setActiveFile}
      deleteFile={deleteFile}
      activeFile={activeFile}
      drawerBody={
        activeOrder?.declaration === undefined ||
        activeOrder?.declaration.length === 0
          ? NotDeclaredBody
          : DeclaredBody
      }
      btn={
        activeOrder?.declaration === undefined ||
        activeOrder?.declaration.length === 0 ? (
          <BtnStandardIconText
            mode=""
            txt={Returntext(contentManagementData, "addProduct", lang)}
            disabled={false}
            size={"standard"}
            icon={false}
            mainColor={"green"}
            onClick={() => createNewDeclaration(activeOrder)}
          />
        ) : (
          <BtnStandardIconText
            mode=""
            txt={Returntext(contentManagementData, "addProductMore", lang)}
            disabled={false}
            size={"standard"}
            icon={true}
            iconPosition={"left"}
            mainColor={"grayBorder"}
            onClick={triggerMoreProductDrawer}
            svg={<AddIcon />}
          />
        )
      }
    />
  );

  const DeclarationDrawer = (
    <Drawer
      headerTitle={Returntext(
        contentManagementData,
        "declarationDrawerTitle",
        lang
      )}
      body={DrawerBody}
      onClick={() => {
        updateDeclaration();
      }}
      cancel={
        activeOrder?.declaration_status !== "DECLERED" &&
        activeOrder?.declaration !== undefined &&
        activeOrder?.declaration.length !== 0
          ? () => setDeclareClose(true)
          : () => cancelClick()
      }
      btnMainTxt={Returntext(contentManagementData, "declareParcel", lang)}
      mainBtnDisable={!activeOrder?.buttons?.declaration_enable}
      declareClose={declareClose}
    />
  );

  const TrackingDrawerBody = trackingDrawerActive && (
    <OrderDrawerBody
      tracking={tracking}
      setTracking={setTracking}
      title={title}
      setTitle={setTitle}
      country={country}
      setCountry={setCountry}
      titleError={false}
      trackingError={trackingError}
    />
  );

  const TrackingDrawer = (
    <Drawer
      headerTitle={Returntext(
        contentManagementData,
        "trackingDrawerTitle",
        lang
      )}
      body={TrackingDrawerBody}
      onClick={() => {
        addOrder();
      }}
      cancel={cancelClick}
      btnMainTxt={Returntext(contentManagementData, "addTracking", lang)}
      size={"s"}
    />
  );

  const TrackingWayDrawerComponent = (
    <TrackingWayDrawer cancel={cancelClick} activeOrder={activeOrder} />
  );

  const PInfoDrawerComponent = (
    <PInfoDrawer
      cancel={cancelClick}
      setTipsDrawerActive={setTipsDrawerActive}
      setPInfoDrawer={setPInfoDrawer}
      activeOrder={activeOrder}
      setStep={setStep}
      files={files}
      setFiles={setFiles}
      setActiveOrder={setActiveOrder}
    />
  );

  const VatPInfoComponent = (
    <VatPInfo
      cancel={cancelClick}
      setTipsDrawerActive={setTipsDrawerActive}
      SetVatPInfoDrawer={SetVatPInfoDrawer}
      setVatDrawer={setVatDrawer}
    />
  );

  const TipsDrawerComponent = (
    <TipsDrawer
      cancel={cancelClick}
      setTipsDrawerActive={setTipsDrawerActive}
    />
  );

  const VatDrawerComponent = (
    <vatDrawer
      cancel={cancelClick}
      SetVatPInfoDrawer={SetVatPInfoDrawer}
      setVatDrawer={setVatDrawer}
    />
  );

  return (
    <div className="main-layout-sc">
      <BrowserView>
        <Navbar setPDrawerActive={setPDrawerActive} />
        <Sidebar />
        <div className="main-wrap-sc">
          <div className="inner-wrap">{web}</div>
        </div>
      </BrowserView>
      <MobileView>
        <HeaderAuthed setPDrawerActive={setPDrawerActive} />
        <div className="main-wrap-sc">
          <div className="inner-wrap">{mob}</div>
        </div>
        <NavbarMobile />
      </MobileView>

      {/* <PaymentLoader /> */}

      {popUpActive && (
        <AccountPopUp
          title={Returntext(contentManagementData, "settingsUpH", lang)}
          msg={Returntext(contentManagementData, "settingsUpSH", lang)}
          popUpActive={popUpActive}
          setPopUpActive={setPopUpActive}
        />
      )}

      {aPopUpActive && (
        <AccoundSecondaryEditPP
          title={Returntext(contentManagementData, "update", lang)}
          msg={Returntext(contentManagementData, "updateB", lang)}
          popUpActive={popUpActive}
        />
      )}

      {deleteItem && isWalletRoute() && (
        <div className="delete-address">
          <div className="delete-item-box">
            <div className="top">
              <div className="title">
                {Returntext(contentManagementData, "delete_card", lang)}
              </div>
              <BtnCircular
                size={"sm"}
                mainColor={"white"}
                svg={<CloseIcon />}
                disabled={false}
                mode={"active"}
                onClick={() => setDeleteItem(false)}
              />
            </div>

            <div className="middle">
              <div className="txt">
                {Returntext(
                  contentManagementData,
                  "confirm_payement_delete",
                  lang
                )}
              </div>
            </div>

            <div className="bottom">
              <BtnStandardIconText
                mode=""
                txt={Returntext(contentManagementData, "btn_cancel", lang)}
                disabled={false}
                size={"standard"}
                icon={false}
                mainColor={"white"}
                onClick={() => setDeleteItem(false)}
              />
              <BtnStandardIconText
                mode=""
                txt={Returntext(contentManagementData, "delete", lang)}
                disabled={false}
                size={"standard"}
                icon={false}
                mainColor={"red"}
                onClick={() => handleDeleteCard(activeItem.id)}
              />
            </div>
          </div>
        </div>
      )}

      {showModal && (
        <div className="delete-address">
          <div className="delete-item-box">
            <div className="top">
              <div className="title">
                {Returntext(contentManagementData, "btn_add_card", lang)}
              </div>
              <BtnCircular
                size={"sm"}
                mainColor={"white"}
                svg={<CloseIcon />}
                disabled={false}
                mode={"active"}
                onClick={() => {
                  setShowModal(false);
                  setOrderId("");
                }}
              />
            </div>

            <div className="bg-wrap">
              <PaymentIframe
                orderId={orderId}
                setShowModal={setShowModal}
                setOrderId={setOrderId}
              />
            </div>
          </div>
        </div>
      )}

      {addAddressPopUp && (
        <>
          <AddressPopUp
            setAddAddressPopUp={setAddAddressPopUp}
            setEditAddressPopUp={setEditAddressPopUp}
            type={"create"}
          />
        </>
      )}
      {restrictedItemsDrawer && (
        <>
          <RestrictedItemsDrawer cancelClick={cancelRest} />
        </>
      )}
      {editAddressPopUp && (
        <AddressPopUp
          setEditAddressPopUp={setEditAddressPopUp}
          setAddAddressPopUp={setAddAddressPopUp}
          activeItem={activeItem}
          type={"update"}
        />
      )}

      {deleteOrder && (
        <div className="delete-address">
          <div className="delete-item-box">
            <div className="top">
              <div className="title">
                {Returntext(contentManagementData, "delete_order", lang)}
              </div>
              <BtnCircular
                size={"sm"}
                mainColor={"white"}
                svg={<CloseIcon />}
                disabled={false}
                mode={"active"}
                onClick={() => setDeleteOrder(false)}
              />
            </div>

            <div className="middle">
              <div className="txt">
                {Returntext(
                  contentManagementData,
                  "confirm_order_delete",
                  lang
                )}
              </div>
            </div>

            <div className="bottom">
              <BtnStandardIconText
                mode=""
                txt={Returntext(contentManagementData, "btn_cancel", lang)}
                disabled={false}
                size={"standard"}
                icon={false}
                mainColor={"white"}
                onClick={() => setDeleteOrder(false)}
              />
              <BtnStandardIconText
                mode=""
                txt={Returntext(contentManagementData, "delete", lang)}
                disabled={false}
                size={"standard"}
                icon={false}
                mainColor={"red"}
                onClick={() => handleDeleteOrder(activeItem.id)}
              />
            </div>
          </div>
        </div>
      )}

      {declareClose && (
        <div className="declare-close">
          <div className="delete-item-box">
            <div className="top">
              <div className="title">
                {Returntext(contentManagementData, "declare_confirm", lang)}
              </div>
              <BtnCircular
                size={"sm"}
                mainColor={"white"}
                svg={<CloseIcon />}
                disabled={false}
                mode={"active"}
                onClick={() => {
                  cancelClick();
                  setDeclareClose(false);
                }}
              />
            </div>

            <div className="middle">
              <div className="txt">
                {Returntext(
                  contentManagementData,
                  "declare_confirm_body",
                  lang
                )}
              </div>
            </div>

            <div className="bottom">
              <BtnStandardIconText
                mode=""
                txt={Returntext(contentManagementData, "btn_cancel", lang)}
                disabled={false}
                size={"standard"}
                icon={false}
                mainColor={"white"}
                onClick={() => {
                  cancelClick();
                  setDeclareClose(false);
                }}
              />
              <BtnStandardIconText
                mode=""
                txt={Returntext(contentManagementData, "declareParcel", lang)}
                disabled={false}
                size={"standard"}
                icon={false}
                mainColor={"green"}
                onClick={() => {
                  updateDeclaration();
                  cancelClick();
                  setDeclareClose(false);
                }}
              />
            </div>
          </div>
        </div>
      )}

      {deleteItem && isAddressRoute() && (
        <div className="delete-address">
          <div className="delete-item-box">
            <div className="top">
              <div className="title">
                {Returntext(contentManagementData, "deleteAddressTitle", lang)}
              </div>
              <BtnCircular
                size={"sm"}
                mainColor={"white"}
                svg={<CloseIcon />}
                disabled={false}
                mode={"active"}
                onClick={() => setDeleteItem(false)}
              />
            </div>

            <div className="middle">
              <div className="txt">
                {Returntext(contentManagementData, "deleteAddressBody", lang)}
              </div>
            </div>

            <div className="bottom">
              <BtnStandardIconText
                mode=""
                txt={Returntext(contentManagementData, "cancel", lang)}
                disabled={false}
                size={"standard"}
                icon={false}
                mainColor={"white"}
                onClick={() => setDeleteItem(false)}
              />
              <BtnStandardIconText
                mode=""
                txt={Returntext(contentManagementData, "delete", lang)}
                disabled={false}
                size={"standard"}
                icon={false}
                mainColor={"red"}
                onClick={() => {
                  handleDeleteAddress(activeItem.id);
                  setDeleteItem(false);
                }}
              />
            </div>
          </div>
        </div>
      )}

      {isOrderRoute() && (
        <>
          {trackingDrawerActive && TrackingDrawer}
          {declareDrawerActive && DeclarationDrawer}
          {productDrawerActive && ProductDrawerComponent}
          {customDrawer && CustomComponent}
          {paymentDrawer && PaymentDrawerComponent}
          {deliveryDrawer && DeliveryDrawerComponent}
          {trackingWay && TrackingWayDrawerComponent}
          {pInfoDrawer && PInfoDrawerComponent}
          {tipsDrawerActive && TipsDrawerComponent}
          {vatDrawer && VatDrawerComponent}
          {vatPInfoDrawer && VatPInfoComponent}
          {payedDrawer && PayedDrawerComponent}
          {addressDrawer && (
            <AddressDrawer
              cancelClick={() => setAddressDrawer(false)}
              setUserAddress={setAddress}
            />
          )}
        </>
      )}
    </div>
  );
};

export default MainLayout;
